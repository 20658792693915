import { connect } from 'react-redux';
import StickyAtc from '../components/StickyAtc';
import {
  stickyAtcActuallyModifyNum,
  stickyAtcCreateStoreJs,
  stickyAtcModifyBool,
  stickyAtcModifyNum,
  stickyAtcSearchShop,
  stickyAtcShopNameChanged,
  stickyAtcWidgetTextChangeApi,
  getAllStickyAtcStores,
  stickyAtcWidgetDevShopsAction,
  stickyAtcGetAllPackages,
  stickyAtcGetAllCoupons
} from '../actions/stickyAtc';
import { activateToast } from '../actions/main';

const mapStateToProps = (state) => ({
  stickyAtc: state.stickyAtc,
});

const mapDispatchToProps = (dispatch) => ({
  onShopNameChanged: (text) => dispatch(stickyAtcShopNameChanged(text)),
  onSearch: (token) => dispatch(stickyAtcSearchShop(token)),
  createStoreJs: (token) => dispatch(stickyAtcCreateStoreJs(token)),
  modifyBoolean: (attribute, token) => (bool) =>
    dispatch(stickyAtcModifyBool(attribute, bool, token)),
  modifyWidgetBoolean: (attribute, token) => (bool) =>
    dispatch(stickyAtcModifyBool(attribute, bool, token)),
  onNumberChange: (attribute) => (num) =>
    dispatch(stickyAtcActuallyModifyNum(attribute, num)),
  onSaveNumber: (attribute, token) => () =>
    dispatch(stickyAtcModifyNum(attribute, null, token)),
  widgetTextChange: (attr, token) => (text) =>
    dispatch(stickyAtcWidgetTextChangeApi(attr, text, token)),
  getAllStores: (token) => dispatch(getAllStickyAtcStores(token)),
  widgetDevShopsAction: (token) => dispatch(stickyAtcWidgetDevShopsAction(token)),
  activateToast: (text) => dispatch(activateToast(text)),
  getAllPackages: (token) => dispatch(stickyAtcGetAllPackages(token)),
  getAllCoupons: (token) => dispatch(stickyAtcGetAllCoupons(token))
});

export default connect(mapStateToProps, mapDispatchToProps)(StickyAtc);
