import React from 'react';
import '../css/App.css';
import {Tabs, Frame, Toast} from '@shopify/polaris';
import '@shopify/polaris/dist/styles.css';
import {tabs} from "../actions/nav";
import VisibleApp from "../containers/VisibleApp";
import VisiblePartners from "../containers/VisiblePartners";
import VisibleCrisis from "../containers/VisibleCrisis";
import VisibleStickyAtc from "../containers/VisibleStickyAtc";
import VisibleStickyAtcPackages from "../containers/VisibleStickyAtcPackages";

import { Amplify } from 'aws-amplify';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from '../aws-exports';
import VisibleFreeShippingBar from "../containers/VisibleFreeShippingBar";
import VisibleCrossApp from "../containers/VisibleCrossApp";
import VisibleAdminActions from "../containers/VisibleAdminActions";
import VisiblePermissions from "../containers/VisiblePermissions";
Amplify.configure(awsExports);


const Main = ({nav, handleTabSelect, toastIsActive, toastContent, onDismissToast, signOut, user}) => <Frame>
    <h1>Hello {user.attributes.email}</h1>
    <button onClick={signOut}>Sign out</button>
    { Number.isInteger(nav.selectedTab) &&
    <Tabs selected={nav.selectedTab} tabs={tabs} onSelect={handleTabSelect}>
        { tabs[nav.selectedTab].id === 'upsell' && <VisibleApp /> }
        { tabs[nav.selectedTab].id === 'partners' && <VisiblePartners /> }
        { tabs[nav.selectedTab].id === 'crisis' && <VisibleCrisis /> }
        { tabs[nav.selectedTab].id === 'sticky-atc' && <VisibleStickyAtc /> }
        { tabs[nav.selectedTab].id === 'sticky-atc-packages' && <VisibleStickyAtcPackages />}
        { tabs[nav.selectedTab].id === 'free-shipping-bar' && <VisibleFreeShippingBar /> }
        { tabs[nav.selectedTab].id === 'cross-app-tasks' && <VisibleCrossApp /> }
        { tabs[nav.selectedTab].id === 'admin-actions' && <VisibleAdminActions /> }
        { tabs[nav.selectedTab].id === 'permissions' && <VisiblePermissions /> }
    </Tabs> }
    {toastIsActive && <Toast content={toastContent} onDismiss={onDismissToast} />}
</Frame>;

export default withAuthenticator(Main);
