import React, { useEffect, useState } from "react";
import { useCallback } from "react";
import "../css/App.css";
import {
  Button,
  TextField,
  Spinner,
  Checkbox,
  Select,
  DataTable,
  Card,
  Icon,
  Stack,
  Modal,
  Collapsible,
  OptionList,
  DatePicker,
} from "@shopify/polaris";
import "@shopify/polaris/dist/styles.css";
import ReactSearchBox from "react-search-box";
import { Descope, useSession, useUser, useDescope } from "@descope/react-sdk";
import { getSessionToken } from "@descope/react-sdk";
import { newStickyAtcHost } from "..";
import {
  ViewMinor,
  DeleteMinor,
  ArrowDownMinor,
  ChevronDownMinor,
  ChevronUpMinor,
  DuplicateMinor,
} from "@shopify/polaris-icons";
import { fetchNewStickyAtcApi, updateNewStickyAtcApi } from "../actions/main";

const DeletePackageModal = ({
  deletePackage,
  setDeletePackage,
  activateToast,
  getAllPackages,
}) => {
  const handleDeleteClose = () => {
    setDeletePackage(null);
  };

  const handleDelete = async () => {
    if (deletePackage !== null) {
      const res = await updateNewStickyAtcApi(
        "api/package",
        { id: deletePackage.id },
        getSessionToken(),
        "DELETE"
      );
      if (res.status === 200) {
        activateToast("Package Deleted");
        getAllPackages(getSessionToken());
      } else {
        activateToast("Failed Deleting Package");
      }
      setDeletePackage(null);
    }
  };

  return (
    <Modal
      open={deletePackage !== null}
      onClose={handleDeleteClose}
      title="Are you sure you want to delete this package?"
      primaryAction={{
        destructive: true,
        content: "Delete",
        onAction: handleDelete,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleDeleteClose,
        },
      ]}
    ></Modal>
  );
};

const DeleteCouponModal = ({
  deleteCoupon,
  setDeleteCoupon,
  activateToast,
  getAllCoupons,
}) => {
  const handleDeleteClose = () => {
    setDeleteCoupon(null);
  };

  const handleDelete = async () => {
    if (deleteCoupon !== null) {
      const res = await updateNewStickyAtcApi(
        "api/coupon",
        { id: deleteCoupon.id },
        getSessionToken(),
        "DELETE"
      );
      if (res.status === 200) {
        activateToast("Coupon Deleted");
        getAllCoupons(getSessionToken());
      } else {
        activateToast("Failed Deleting Coupon");
      }
      setDeleteCoupon(null);
    }
  };

  return (
    <Modal
      open={deleteCoupon !== null}
      onClose={handleDeleteClose}
      title="Are you sure you want to delete this coupon?"
      primaryAction={{
        destructive: true,
        content: "Delete",
        onAction: handleDelete,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: handleDeleteClose,
        },
      ]}
    ></Modal>
  );
};

const CreatePackageModal = ({
  stickyAtcPackages,
  createPackage,
  setCreatePackage,
  activateToast,
  getAllPackages,
  viewPackage,
  setViewPackage,
}) => {
  const update = Boolean(viewPackage);

  const linkedTiers = viewPackage?.linkedTiers ?? [];

  const [packageData, setPackageData] = useState({
    name: viewPackage?.name ?? "",
    default: viewPackage?.default ?? false,
  });

  const setPackageDataValue = (key, value) => {
    setPackageData({
      ...packageData,
      [key]: value,
    });
  };

  const tierFields = stickyAtcPackages.tierFields ?? {};
  const features = (stickyAtcPackages.features ?? []).map((feature) => ({
    value: feature.key,
    label: feature.key + " - " + feature.description,
  }));

  const getTierData = () => {
    if (viewPackage?.data?.tiers) {
      return viewPackage.data.tiers.map((tier) => {
        Object.keys({ ...tier }).forEach((key) => {
          if (typeof tier[key] !== "object") tier[key] = tier[key].toString();
        });

        return {
          ...tier,
          features: tier.features.map((feature) => feature.key),
        };
      });
    }

    return [{}];
  };

  const [tierCounts, setTierCounts] = useState(
    (viewPackage?.data?.tiers ?? []).length ?? 1
  );
  const [tierData, setTierData] = useState(getTierData());

  const setTierDataValue = (index, key, value) => {
    const newTierData = [...tierData];
    newTierData[index] = {
      ...newTierData[index],
      [key]: value,
    };
    setTierData(newTierData);
  };

  const addStringArrayValue = (index, key) => {
    const currentArray = tierData[index]?.[key] || [];
    setTierDataValue(index, key, [...currentArray, ""]);
  };

  const updateStringArrayValue = (index, key, arrayIndex, value) => {
    const currentArray = [...(tierData[index]?.[key] || [])];
    currentArray[arrayIndex] = value;
    setTierDataValue(index, key, currentArray);
  };

  const removeStringArrayValue = (index, key, arrayIndex) => {
    const currentArray = [...(tierData[index]?.[key] || [])];
    currentArray.splice(arrayIndex, 1);
    setTierDataValue(index, key, currentArray);
  };

  const [tierOpen, setTierOpen] = useState({});

  const submitPackage = async () => {
    if (packageData.name === "") {
      activateToast("Name is required");
      return;
    }

    const tierDataValid = tierData.every((tier) => {
      return Object.keys(tierFields)
        .filter((key) => typeof tierFields[key] !== "object")
        .every((key) => {
          if (tierFields[key] === "boolean") return true;

          if (tier[key] === undefined || tier[key] === "") return false;

          if (tierFields[key] === "number" && isNaN(tier[key])) return false;

          return true;
        });
    });

    if (!tierDataValid) {
      activateToast("Invalid Tier Data");
      return;
    }

    const payload = {
      name: packageData.name,
      default: packageData.default,
      data: {
        tiers: tierData.map((tier) => {
          return Object.keys(tier).reduce((acc, key) => {
            if (tierFields[key] === "number") acc[key] = Number(tier[key]);
            else if (
              tierFields[key] === "boolean" &&
              typeof tier[key] === "string"
            )
              acc[key] = tier[key] === "true";
            else acc[key] = tier[key];
            return acc;
          }, {});
        }),
      },
    };

    payload.data.tiers = payload.data.tiers.map((tier) => {
      return {
        ...tier,
        features: tier.features ?? [],
      };
    });

    if (update) payload.id = viewPackage.id;

    const res = await updateNewStickyAtcApi(
      "api/package",
      payload,
      getSessionToken(),
      update ? "PUT" : "POST"
    );
    if (res.status === 200) {
      activateToast("Package Created");
      getAllPackages(getSessionToken());
      setCreatePackage(false);
    } else {
      activateToast("Failed Creating Package");
    }
  };

  const canDeleteTier = (index) => {
    if (update) {
      const currTier = tierData[index];
      if (!currTier) return false;
      return !linkedTiers.includes(currTier.key);
    }

    return tierCounts > 1;
  };

  return (
    <Modal
      open={createPackage}
      onClose={() => {
        setCreatePackage(false);
        setViewPackage(null);
      }}
      primaryAction={{
        content: "Submit",
        onAction: submitPackage,
      }}
      title={update ? "Update Package" : "Create Package"}
    >
      <Modal.Section>
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <TextField
            label="Name"
            disabled={update && viewPackage?.hasLinkedShops}
            value={packageData.name}
            onChange={(value) => setPackageDataValue("name", value)}
          />
          <Checkbox
            label="Default"
            checked={packageData.default}
            onChange={(value) => setPackageDataValue("default", value)}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h1
              style={{
                fontWeight: "bold",
              }}
            >
              Tiers
            </h1>

            <Button
              onClick={() => {
                setTierCounts(tierCounts + 1);
                setTierOpen({
                  ...tierOpen,
                  [tierCounts]: true,
                });
              }}
            >
              Add Tier
            </Button>
          </div>

          {Array.from({ length: tierCounts }).map((_, index) => (
            <Card
              title={`Tier ${index + 1}`}
              key={index}
              actions={[
                canDeleteTier(index) && {
                  content: "Delete",
                  onAction: () => {
                    const newTierData = [...tierData];
                    newTierData.splice(index, 1);
                    setTierData(newTierData);
                    setTierCounts(tierCounts - 1);
                    setTierOpen({});
                  },
                },
                {
                  content: (
                    <div>
                      <Icon
                        source={
                          tierOpen[index] ? ChevronUpMinor : ChevronDownMinor
                        }
                      />
                    </div>
                  ),
                  onAction: () => {
                    setTierOpen({
                      ...tierOpen,
                      [index]: !tierOpen[index],
                    });
                  },
                },
              ]}
            >
              {!tierOpen[index] && <div style={{ height: "16px" }} />}

              <Collapsible open={tierOpen[index]}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    padding: "16px",
                  }}
                >
                  {Object.keys(tierFields)
                    .filter((key) => typeof tierFields[key] !== "object")
                    .map((key) => {
                      if (tierFields[key] === "boolean") {
                        console.log(
                          tierData[index]?.[key],
                          typeof tierData[index]?.[key],
                          key
                        );
                        return (
                          <Checkbox
                            key={key}
                            label={key}
                            checked={
                              typeof tierData[index]?.[key] === "string"
                                ? (tierData[index]?.[key] ?? "false") === "true"
                                : tierData[index]?.[key] ?? false
                            }
                            onChange={(value) => {
                              setTierDataValue(index, key, value);
                            }}
                          />
                        );
                      }

                      if (tierFields[key] === "string[]") {
                        return (
                          <div key={key}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <p>{key}</p>
                              <Button
                                onClick={() => addStringArrayValue(index, key)}
                              >
                                Add Option
                              </Button>
                            </div>
                            {(tierData[index]?.[key] || []).map(
                              (value, arrayIndex) => (
                                <div
                                  key={arrayIndex}
                                  style={{
                                    display: "flex",
                                    gap: "8px",
                                    marginTop: "8px",
                                  }}
                                >
                                  <TextField
                                    value={value}
                                    onChange={(newValue) =>
                                      updateStringArrayValue(
                                        index,
                                        key,
                                        arrayIndex,
                                        newValue
                                      )
                                    }
                                  />
                                  <Button
                                    destructive
                                    onClick={() =>
                                      removeStringArrayValue(
                                        index,
                                        key,
                                        arrayIndex
                                      )
                                    }
                                  >
                                    Remove
                                  </Button>
                                </div>
                              )
                            )}
                          </div>
                        );
                      }

                      return (
                        <TextField
                          key={key}
                          label={key}
                          disabled={key === "key" && update}
                          type={
                            tierFields[key] === "number" ? "number" : "text"
                          }
                          onChange={(value) => {
                            setTierDataValue(index, key, value);
                          }}
                          value={tierData[index]?.[key] ?? ""}
                        />
                      );
                    })}

                  <OptionList
                    title="Features"
                    allowMultiple
                    options={features}
                    selected={tierData[index]?.features ?? []}
                    onChange={(value) => {
                      setTierDataValue(index, "features", value);
                    }}
                  />
                </div>
              </Collapsible>
            </Card>
          ))}
        </div>
      </Modal.Section>
    </Modal>
  );
};

const CreateCouponModal = ({
  stickyAtcPackages,
  createCoupon,
  setCreateCoupon,
  activateToast,
  getAllCoupons,
  viewCoupon,
  setViewCoupon,
}) => {
  const update = Boolean(viewCoupon);

  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    return d.toISOString().split("T")[0];
  };

  const [couponData, setCouponData] = useState({
    key: viewCoupon?.key ?? "",
    discountType: viewCoupon?.discountType ?? "percentage",
    discountAmount: viewCoupon?.discountAmount
      ? viewCoupon.discountAmount.toString()
      : "",
    applyDate: formatDate(viewCoupon?.applyDate),
    expireDate: formatDate(viewCoupon?.expireDate),
  });

  const setCouponDataValue = (key, value) => {
    setCouponData({
      ...couponData,
      [key]: value,
    });
  };

  const submitCoupon = async () => {
    if (!couponData.key) {
      activateToast("Key is required");
      return;
    }

    if (!couponData.discountType) {
      activateToast("Discount Type is required");
      return;
    }

    if (!couponData.discountAmount) {
      activateToast("Discount Amount is required");
      return;
    }

    const payload = {
      key: couponData.key,
      discountType: couponData.discountType,
      discountAmount: +couponData.discountAmount,
      applyDate: couponData.applyDate
        ? new Date(couponData.applyDate).toISOString()
        : null,
      expireDate: couponData.expireDate
        ? new Date(couponData.expireDate).toISOString()
        : null,
    };

    if (update) payload.id = viewCoupon.id;

    const res = await updateNewStickyAtcApi(
      "api/coupon",
      payload,
      getSessionToken(),
      update ? "PUT" : "POST"
    );
    if (res.status === 200) {
      activateToast("Coupon Created");
      getAllCoupons(getSessionToken());
      setCreateCoupon(false);
    } else {
      activateToast("Failed Creating Coupon");
    }
  };

  return (
    <Modal
      open={createCoupon}
      onClose={() => {
        setCreateCoupon(false);
        setViewCoupon(null);
      }}
      primaryAction={{
        content: "Submit",
        onAction: submitCoupon,
      }}
      title={update ? "Update Coupon" : "Create Coupon"}
    >
      <Modal.Section>
        <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <TextField
            label="Key"
            value={couponData.key}
            onChange={(value) => setCouponDataValue("key", value)}
          />
          <Select
            label="Discount Type"
            value={couponData.discountType}
            onChange={(value) => setCouponDataValue("discountType", value)}
            options={[
              { label: "Percentage", value: "percentage" },
              { label: "Fixed", value: "fixed" },
            ]}
          />
          <TextField
            label="Discount Amount"
            value={couponData.discountAmount}
            onChange={(value) => setCouponDataValue("discountAmount", value)}
          />
          <TextField
            type="date"
            label="Apply Date"
            value={couponData.applyDate}
            onChange={(value) => setCouponDataValue("applyDate", value)}
          />
          <TextField
            type="date"
            label="Expire Date"
            value={couponData.expireDate}
            onChange={(value) => setCouponDataValue("expireDate", value)}
          />
        </div>
      </Modal.Section>
    </Modal>
  );
};

const StickyAtcPackages = ({
  stickyAtc,
  getAllPackages,
  getAllStores,
  activateToast,
  getAllCoupons,
}) => {
  const { isAuthenticated, isSessionLoading } = useSession();
  const { isUserLoading } = useUser();
  const sdk = useDescope();
  const logout = sdk.logout;

  const token = getSessionToken();

  const permissions = token
    ? sdk.getJwtPermissions(getSessionToken()) ?? []
    : [];
  const packagePermission = permissions.includes("manage-packages");
  const couponPermission = permissions.includes("manage-coupons");

  useEffect(() => {
    if (isAuthenticated) {
      if (packagePermission) getAllPackages(getSessionToken());
      if (couponPermission) getAllCoupons(getSessionToken());
    }
  }, [
    getAllStores,
    getAllPackages,
    isAuthenticated,
    getAllCoupons,
    packagePermission,
    couponPermission,
  ]);

  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  useEffect(() => {
    try {
      const valid = sdk.isJwtExpired(token);
      if (!valid) sdk.getRefreshToken();
    } catch (error) {
      console.error(error);
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, logout]);

  const [deletePackage, setDeletePackage] = useState(null);
  const [viewPackage, setViewPackage] = useState(null);
  const [createPackage, setCreatePackage] = useState(false);

  const [deleteCoupon, setDeleteCoupon] = useState(null);
  const [viewCoupon, setViewCoupon] = useState(null);
  const [createCoupon, setCreateCoupon] = useState(false);

  const handleDuplicate = async (packageData) => {
    const res = await updateNewStickyAtcApi(
      "api/package",
      { duplicate: true, id: packageData.id },
      getSessionToken(),
      "POST"
    );
    if (res.status === 200) {
      activateToast("Package Duplicated");
      getAllPackages(getSessionToken());
    } else {
      activateToast("Failed Duplicating Package");
    }
  };

  const rows = (stickyAtc?.packages?.packages ?? []).map((packageData) => {
    return [
      <span>{packageData.name}</span>,
      <span>{new Date(packageData.createdAt).toLocaleString()}</span>,
      <span>{packageData.default ? "Yes" : "No"}</span>,
      <Stack>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            setViewPackage(packageData);
            setCreatePackage(true);
          }}
        >
          <Icon source={ViewMinor} />
        </span>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleDuplicate(packageData);
          }}
        >
          <Icon source={DuplicateMinor} />
        </span>
        {!packageData.hasLinkedShops && !packageData.default && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setDeletePackage(packageData);
            }}
          >
            <Icon source={DeleteMinor} />
          </span>
        )}
      </Stack>,
    ];
  });

  const getUTCDate = (date) => {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const couponsRows = (stickyAtc?.coupons ?? []).map((coupon) => {
    return [
      <span>{coupon.key}</span>,
      <span>{coupon.discountType}</span>,
      <span>{coupon.discountAmount}</span>,
      <span>
        {coupon.applyDate ? getUTCDate(new Date(coupon.applyDate)) : "-"}
      </span>,
      <span>
        {coupon.expireDate ? getUTCDate(new Date(coupon.expireDate)) : "-"}
      </span>,
      <Stack>
        <span
          style={{ cursor: "pointer" }}
          onClick={() => {
            setViewCoupon(coupon);
            setCreateCoupon(true);
          }}
        >
          <Icon source={ViewMinor} />
        </span>
        {!coupon.hasLinkedShops && (
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              setDeleteCoupon(coupon);
            }}
          >
            <Icon source={DeleteMinor} />
          </span>
        )}
      </Stack>,
    ];
  });

  const loading = stickyAtc.gettingAllPackages || stickyAtc.gettingAllCoupons;

  if (!isAuthenticated)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Descope
          flowId="sign-up-or-in"
          onSuccess={() => {
            console.log("success");
          }}
        />
      </div>
    );

  if (isSessionLoading || isUserLoading)
    return (
      <div>
        <Spinner />
      </div>
    );

  if (!packagePermission && !couponPermission)
    return (
      <div>
        <h1>No Permissions</h1>
      </div>
    );

  return (
    <div>
      {loading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <div>
          <button onClick={handleLogout}>Google Logout</button>

          {deletePackage && packagePermission && (
            <DeletePackageModal
              deletePackage={deletePackage}
              setDeletePackage={setDeletePackage}
              activateToast={activateToast}
              getAllPackages={getAllPackages}
            />
          )}

          {createPackage && packagePermission && (
            <CreatePackageModal
              stickyAtcPackages={stickyAtc.packages}
              createPackage={createPackage}
              setCreatePackage={setCreatePackage}
              activateToast={activateToast}
              getAllPackages={getAllPackages}
              viewPackage={viewPackage}
              setViewPackage={setViewPackage}
            />
          )}

          {deleteCoupon && couponPermission && (
            <DeleteCouponModal
              deleteCoupon={deleteCoupon}
              setDeleteCoupon={setDeleteCoupon}
              activateToast={activateToast}
              getAllCoupons={getAllCoupons}
            />
          )}

          {createCoupon && couponPermission && (
            <CreateCouponModal
              stickyAtcPackages={stickyAtc.packages}
              createCoupon={createCoupon}
              setCreateCoupon={setCreateCoupon}
              activateToast={activateToast}
              getAllCoupons={getAllCoupons}
              viewCoupon={viewCoupon}
              setViewCoupon={setViewCoupon}
            />
          )}

          {packagePermission && (
            <Card
              title="Packages"
              actions={[
                {
                  content: "Create Package",
                  onAction: () => {
                    setCreatePackage(true);
                  },
                },
              ]}
            >
              <DataTable
                columnContentTypes={["text", "text", "text", "text"]}
                headings={["Name", "Created Date", "Default", "Actions"]}
                rows={rows}
              />
            </Card>
          )}

          {couponPermission && (
            <div
              style={{
                margin: "32px 0px",
              }}
            >
              <Card
                title="Coupons"
                actions={[
                  {
                    content: "Create Coupon",
                    onAction: () => {
                      setCreateCoupon(true);
                    },
                  },
                ]}
              >
                <DataTable
                  columnContentTypes={[
                    "text",
                    "text",
                    "text",
                    "text",
                    "text",
                    "text",
                  ]}
                  headings={[
                    "Key",
                    "Type",
                    "Amount",
                    "Apply Date",
                    "Expire Date (until used)",
                    "Actions",
                  ]}
                  rows={couponsRows}
                />
              </Card>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StickyAtcPackages;
