import fetch from 'cross-fetch';
import { host, newStickyAtcHost, stickyAtcHost } from '../index';
import { Auth } from 'aws-amplify';

export const ON_SHOP_NAME_CHANGED = 'ON_SHOP_NAME_CHANGED';
export const MONEY_CSS_CLASS_CHANGE = 'MONEY_CSS_CLASS_CHANGE';
export const REVIEW_REQUEST_EMAIL_CHANGE = 'REVIEW_REQUEST_EMAIL_CHANGE';
export const PARTNER_EMAIL_CHANGE = 'PARTNER_EMAIL_CHANGE';
export const SEARCHING = 'SEARCHING';
export const SEND_REVIEW_REQUEST_SUCCESS = 'SEND_REVIEW_REQUEST_SUCCESS';
export const SEND_REVIEW_REQUEST_FAILURE = 'SEND_REVIEW_REQUEST_FAILURE';
export const SEARCH_ERROR = 'SEARCH_ERROR';
export const GENERATE_PARTNER_LINK_ERROR = 'GENERATE_PARTNER_LINK_ERROR';
export const WAKE_UP_ARIEL_SUCCESS = 'WAKE_UP_ARIEL_SUCCESS';
export const WAKE_UP_ARIEL_FAILURE = 'WAKE_UP_ARIEL_FAILURE';
export const GENERATE_PARTNER_LINK_SUCCESS = 'GENERATE_PARTNER_LINK_SUCCESS';
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS';
export const SET_WIDGET_VERSION = 'SET_WIDGET_VERSION';
export const SET_STORES = 'SET_STORES';
export const TOGGLE_ATTRIBUTES = 'TOGGLE_ATTRIBUTES';
export const GETTING_ALL_STORES = 'GETTING_ALL_STORES';
export const SENDING_REVIEW_REQUEST = 'SENDING_REVIEW_REQUEST';
export const GETTING_ALL_STORES_ERROR = 'GETTING_ALL_STORES_ERROR';
export const RESYNC = 'RESYNC';
export const SUBMITTING_SETTINGS = 'SUBMITTING_SETTINGS';
export const INSTALLING_SCRIPTS = 'INSTALLING_SCRIPTS';
export const ADDING_SCRIPT_TAG = 'ADDING_SCRIPT_TAG';
export const REMOVING_SCRIPT_TAG = 'REMOVING_SCRIPT_TAG';
export const CREATING_STORE_JS = 'CREATING_STORE_JS';
export const RESYNC_SUCCESS = 'RESYNC_SUCCESS';
export const SUBMIT_SETTINGS_SUCCESS = 'SUBMIT_SETTINGS_SUCCESS';
export const INSTALL_SCRIPTS_SUCCESS = 'INSTALL_SCRIPTS_SUCCESS';
export const CREATE_STORE_JS_SUCCESS = 'CREATE_STORE_JS_SUCCESS';
export const DISMISS_TOAST = 'DISMISS_TOAST';
export const OPEN_SEND_REVIEW_REQUEST = 'OPEN_SEND_REVIEW_REQUEST';
export const PACKAGE_CHANGE_ERROR = 'PACKAGE_CHANGE_ERROR';
export const TOGGLE_BOOL_SUCCESS = 'TOGGLE_BOOL_SUCCESS';
export const TOGGLE_GEN_SETTINGS_BOOL_SUCCESS =
  'TOGGLE_GEN_SETTINGS_BOOL_SUCCESS';
export const WIDGET_VERSION_CHANGING = 'WIDGET_VERSION_CHANGING';
export const NUMBER_CHANGE = 'NUMBER_CHANGE';
export const TOGGLE_NUMBER_SUCCESS = 'TOGGLE_NUMBER_SUCCESS';
export const TOGGLE_WIDGET_DEV_PROCESSING = 'TOGGLE_WIDGET_DEV_PROCESSING';
export const SET_WIDGET_DEV = 'SET_WIDGET_DEV';
export const SET_WIDGET_VERSION_LOCK = 'SET_WIDGET_VERSION_LOCK';
export const SET_DISCOUNT_VERSION = 'SET_DISCOUNT_VERSION';
export const TOGGLE_NUMBER_ERROR = 'TOGGLE_NUMBER_ERROR';
export const TOGGLE_BOOL_PROCESSING = 'TOGGLE_BOOL_PROCESSING';
export const TOGGLE_NUMBER_PROCESSING = 'TOGGLE_NUMBER_PROCESSING';
export const SET_PACKAGE_PROCESSING = 'SET_PACKAGE_PROCESSING';
export const PACKAGE_CHANGE_SUCCESS = 'PACKAGE_CHANGE_SUCCESS';
export const ACTIVATE_TOAST = 'ACTIVATE_TOAST';

export const onShopNameChanged = (text) => ({
  type: ON_SHOP_NAME_CHANGED,
  text,
});

export const activateToast = (text) => ({
  type: ACTIVATE_TOAST,
  text,
});

export const moneyCssClassChange = (text) => ({
  type: MONEY_CSS_CLASS_CHANGE,
  text,
});

export const reviewRequestEmailChange = (text) => ({
  type: REVIEW_REQUEST_EMAIL_CHANGE,
  text,
});

export const partnerEmailChange = (text) => ({
  type: PARTNER_EMAIL_CHANGE,
  text,
});

export const searchSuccess = (json) => ({
  type: SEARCH_SUCCESS,
  json,
});

export const setWidgetVersion = (json) => ({
  type: SET_WIDGET_VERSION,
  json,
});

export const setStores = (json) => ({
  type: SET_STORES,
  shopNames: json.shop_names,
  modifiableBooleans: json.modifiable_booleans,
  modifiableNumbers: json.modifiable_numbers,
  booleans: json.booleans,
  packages: json.packages,
});

export const searching = () => ({
  type: SEARCHING,
});

export const sendReviewRequestSuccess = () => ({
  type: SEND_REVIEW_REQUEST_SUCCESS,
});

export const sendReviewRequestFailure = () => ({
  type: SEND_REVIEW_REQUEST_FAILURE,
});

export const toggleBoolProcessing = () => ({
  type: TOGGLE_BOOL_PROCESSING,
});

export const toggleNumberProcessing = () => ({
  type: TOGGLE_NUMBER_PROCESSING,
});

export const setPackageProcessing = () => ({
  type: SET_PACKAGE_PROCESSING,
});

export const packageChangeSuccess = (id) => ({
  type: PACKAGE_CHANGE_SUCCESS,
  id,
});

export const searchError = () => ({
  type: SEARCH_ERROR,
});

export const generatePartnerLinkError = () => ({
  type: GENERATE_PARTNER_LINK_ERROR,
});

export const wakeUpArielSuccess = () => ({
  type: WAKE_UP_ARIEL_SUCCESS,
});

export const wakeUpArielFailure = () => ({
  type: WAKE_UP_ARIEL_FAILURE,
});

export const generatePartnerLinkSuccess = () => ({
  type: GENERATE_PARTNER_LINK_SUCCESS,
});

export const toggleAttributes = () => ({
  type: TOGGLE_ATTRIBUTES,
});

export const gettingAllStores = () => ({
  type: GETTING_ALL_STORES,
});

export const sendingReviewRequest = () => ({
  type: SENDING_REVIEW_REQUEST,
});

export const gettingAllStoresError = () => ({
  type: GETTING_ALL_STORES_ERROR,
});

export const resync = () => ({
  type: RESYNC,
});

export const submittingSettings = () => ({
  type: SUBMITTING_SETTINGS,
});

export const installingScripts = () => ({
  type: INSTALLING_SCRIPTS,
});

export const addingScriptTag = () => ({
  type: ADDING_SCRIPT_TAG,
});

export const removingScriptTag = () => ({
  type: REMOVING_SCRIPT_TAG,
});

export const creatingStoreJs = () => ({
  type: CREATING_STORE_JS,
});

export const resyncSuccess = () => ({
  type: RESYNC_SUCCESS,
});

export const submitSettingsSuccess = () => ({
  type: SUBMIT_SETTINGS_SUCCESS,
});

export const installScriptsSuccess = () => ({
  type: INSTALL_SCRIPTS_SUCCESS,
});

export const createStoreJsSuccess = () => ({
  type: CREATE_STORE_JS_SUCCESS,
});

export const dismissToast = () => ({
  type: DISMISS_TOAST,
});

export const openSendReviewRequest = () => ({
  type: OPEN_SEND_REVIEW_REQUEST,
});

export const packageChangeError = () => ({
  type: PACKAGE_CHANGE_ERROR,
});

export const toggleBoolSuccess = (key, bool) => ({
  type: TOGGLE_BOOL_SUCCESS,
  key,
  bool,
});

export const toggleGenSettingsBoolSuccess = (key, bool) => ({
  type: TOGGLE_GEN_SETTINGS_BOOL_SUCCESS,
  key,
  bool,
});

export const widgetVersionChanging = (bool) => ({
  type: WIDGET_VERSION_CHANGING,
  bool,
});

export const numberChange = (key, number) => ({
  type: NUMBER_CHANGE,
  key,
  number,
});

export const toggleNumberSuccess = (key) => ({
  type: TOGGLE_NUMBER_SUCCESS,
  key,
});

export const toggleWidgetDevProcessing = (bool) => ({
  type: TOGGLE_WIDGET_DEV_PROCESSING,
  bool,
});

export const setWidgetDev = (num) => ({
  type: SET_WIDGET_DEV,
  num,
});

export const setWidgetVersionLock = (text) => ({
  type: SET_WIDGET_VERSION_LOCK,
  text,
});

export const setDiscountVersion = (text) => ({
  type: SET_DISCOUNT_VERSION,
  text,
});

export const toggleNumberError = (key) => ({
  type: TOGGLE_NUMBER_ERROR,
  key,
});

export const fetchApi = (path, payload) =>
  fetch(`https://${host}/${path}`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

export const fetchStickyAtcApi = (path, payload) =>
  fetch(`https://${stickyAtcHost}/${path}`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

export const fetchNewStickyAtcApi = (path, accessToken) =>
  fetch(`https://${newStickyAtcHost}/${path}`, {
    method: 'get',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const actionNewStickyAtcApi = (path, accessToken) =>
  fetch(`https://${newStickyAtcHost}/${path}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

export const updateNewStickyAtcApi = (path, payload, accessToken, method) =>
  fetch(`https://${newStickyAtcHost}/${path}`, {
    method: method ?? 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(payload),
  });

export const postNewStickyAtcApi = (path, payload, accessToken) =>
  fetch(`https://${newStickyAtcHost}/${path}`, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(payload),
  });

export const fetchToken = async () => {
  const data = await Auth.currentAuthenticatedUser();
  return data.signInUserSession.accessToken.jwtToken;
};
export function searchShop() {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(searching());
    const response = await fetchApi('crm/search_sec', {
      shop_name: state.main.shopName,
      token: await fetchToken(),
    });
    if (response.ok) {
      const json = await response.json();
      dispatch(searchSuccess(json));
    } else {
      dispatch(searchError());
    }
  };
}

export function generatePartnerLink() {
  return async (dispatch, getState) => {
    const state = getState();
    const response = await fetchApi('crm/gen_partner_link_sec', {
      email: state.main.partnerEmail,
      token: await fetchToken(),
    });
    if (response.ok) {
      const json = await response.json();
      await navigator.clipboard.writeText(json.link);
      dispatch(generatePartnerLinkSuccess());
    } else {
      dispatch(generatePartnerLinkError());
    }
  };
}

export function wakeUpAriel() {
  return async (dispatch, getState) => {
    const response = await fetchApi('crm/wake_up_sec', {
      token: await fetchToken(),
    });
    if (response.ok) {
      dispatch(wakeUpArielSuccess());
    } else {
      dispatch(wakeUpArielFailure());
    }
  };
}

export function deleteProductWebhooks() {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(searching());
    const response = await fetchApi('crm/delete_product_webhooks_sec', {
      shop_name: state.main.shopName,
      token: await fetchToken(),
    });
    if (response.ok) {
      dispatch(searchShop());
    } else {
      dispatch(searchError());
    }
  };
}

export function createProductWebhooks() {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(searching());
    const response = await fetchApi('crm/create_product_webhooks_sec', {
      shop_name: state.main.shopName,
      token: await fetchToken(),
    });
    if (response.ok) {
      dispatch(searchShop());
    } else {
      dispatch(searchError());
    }
  };
}

export function hardRefresh() {
  return (dispatch, getState) => {
    const state = getState();
    dispatch(resync());
    return fetch(`https://${host}/resync`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        shop_name: state.main.shopName,
        token: state.main.attributes.access_token,
        ignore_uses_webhooks: true,
      }),
    }).then((response) => {
      if (response.ok) {
        dispatch(resyncSuccess());
      } else {
        dispatch(searchError());
      }
    });
  };
}

export function submitSettings() {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(submittingSettings());
    const response = await fetchApi('crm/save_settings_sec', {
      shop_name: state.main.shopName,
      token: await fetchToken(),
      general_settings: state.main.generalSettings.values,
    });
    if (response.ok) {
      dispatch(submitSettingsSuccess());
    } else {
      dispatch(searchError());
    }
  };
}

export function installScripts() {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(installingScripts());
    const response = await fetchApi('crm/install_snippets_sec', {
      shop_name: state.main.shopName,
      token: await fetchToken(),
    });
    if (response.ok) {
      dispatch(installScriptsSuccess());
    } else {
      dispatch(searchError());
    }
  };
}

export function addScriptTag() {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(addingScriptTag());
    const response = await fetchApi('crm/add_script_tag_sec', {
      shop_name: state.main.shopName,
      token: await fetchToken(),
    });
    if (response.ok) {
      dispatch(searchShop());
    } else {
      dispatch(searchError());
    }
  };
}

export function removeScriptTag() {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(removingScriptTag());
    const response = await fetchApi('crm/remove_script_tag_sec', {
      shop_name: state.main.shopName,
      token: await fetchToken(),
    });
    if (response.ok) {
      dispatch(searchShop());
    } else {
      dispatch(searchError());
    }
  };
}

export function createStoreJs() {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(creatingStoreJs());
    const response = await fetchApi('crm/create_store_js_sec', {
      shop_name: state.main.shopName,
      token: await fetchToken(),
    });
    if (response.ok) {
      dispatch(createStoreJsSuccess());
    } else {
      dispatch(searchError());
    }
  };
}

export function sendReviewRequest() {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(sendingReviewRequest());
    const response = await fetchApi('crm/send_review_email_sec', {
      shop_name: state.main.shopName,
      token: await fetchToken(),
      email: state.main.reviewRequestEmail,
    });
    if (response.ok) {
      dispatch(sendReviewRequestSuccess());
    } else {
      dispatch(sendReviewRequestFailure());
    }
  };
}

export function getAllStores(token) {
  return async (dispatch, getState) => {
    dispatch(gettingAllStores());
    let response = await fetchApi('crm/get_all_stores_sec', { token });
    if (response.ok) {
      const json = await response.json();
      dispatch(setStores(json));
      response = await fetchApi('crm/get_widget_versions', { token });
      if (response.ok) {
        const json = await response.json();
        dispatch(setWidgetVersion(json));
      }
    } else {
      dispatch(gettingAllStoresError());
    }
  };
}

export function booleanChange(key, bool) {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(toggleBoolProcessing());
    const response = await fetchApi('crm/toggle_bool_sec', {
      shop_name: state.main.shopName,
      token: await fetchToken(),
      key,
      bool,
    });
    if (response.ok) {
      dispatch(toggleBoolSuccess(key, bool));
    } else {
      dispatch(searchError());
    }
  };
}

export function genSettingsBooleanChange(key, bool) {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(toggleBoolProcessing());
    const response = await fetchApi('crm/change_gen_setting_attr_sec', {
      shop_name: state.main.shopName,
      token: await fetchToken(),
      key,
      value: bool,
    });
    if (response.ok) {
      dispatch(toggleGenSettingsBoolSuccess(key, bool));
    } else {
      dispatch(searchError());
    }
  };
}

export function saveNumber(key) {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(toggleNumberProcessing());
    const response = await fetchApi('crm/change_shop_attr_sec', {
      shop_name: state.main.shopName,
      token: await fetchToken(),
      key: key,
      value: state.main.attributes[key],
    });
    if (response.ok) {
      dispatch(toggleNumberSuccess(key));
    } else {
      dispatch(toggleNumberError(key));
    }
  };
}

export function widgetDevChange(devNumber) {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(toggleWidgetDevProcessing(true));
    const response = await fetchApi('crm/change_shop_attr_sec', {
      shop_name: state.main.shopName,
      token: await fetchToken(),
      key: 'widget_dev',
      value: devNumber > 0 ? parseInt(devNumber) : null,
    });
    dispatch(toggleWidgetDevProcessing(false));
    if (response.ok) dispatch(setWidgetDev(devNumber));
    dispatch(activateToast(response.ok ? 'Success!' : 'Failed :('));
  };
}

export function widgetVersionChange(text) {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(widgetVersionChanging(true));
    const response = await fetchApi('crm/change_shop_attr_sec', {
      shop_name: state.main.shopName,
      token: await fetchToken(),
      key: 'widget_version_lock',
      value: text === 'Latest' ? null : text,
    });
    if (response.ok) {
      dispatch(activateToast('Version locked!'));
      dispatch(setWidgetVersionLock(text));
    } else {
      dispatch(activateToast('Failed to lock version :('));
    }
    dispatch(widgetVersionChanging(false));
  };
}

export function updateDiscountVersionChange(text) {
  return async (dispatch, getState) => {
    const state = getState();
    const response = await fetchApi('crm/change_shop_attr_sec', {
      shop_name: state.main.shopName,
      token: await fetchToken(),
      key: 'update_discount_version',
      value: text,
    });
    if (response.ok) {
      dispatch(activateToast('Updated!'));
      dispatch(setDiscountVersion(text));
    } else {
      dispatch(activateToast('Failed to update :('));
    }
  };
}

export function packageChange(id) {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(setPackageProcessing());
    const response = await fetchApi('crm/change_shop_attr_sec', {
      shop_name: state.main.shopName,
      token: await fetchToken(),
      key: 'package_id',
      value: id,
    });
    if (response.ok) {
      dispatch(packageChangeSuccess(id));
    } else {
      dispatch(packageChangeError());
    }
  };
}
