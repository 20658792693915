import { connect } from 'react-redux';
import StickyAtcPackages from '../components/StickyAtcPackages';
import {
    stickyAtcShopNameChanged,
    getAllStickyAtcStores,
    stickyAtcGetAllPackages,
    stickyAtcGetAllCoupons
} from '../actions/stickyAtc';
import { activateToast } from '../actions/main';

const mapStateToProps = (state) => ({
    stickyAtc: state.stickyAtc,
});

const mapDispatchToProps = (dispatch) => ({
    getAllStores: (token) => dispatch(getAllStickyAtcStores(token)),
    getAllPackages: (token) => dispatch(stickyAtcGetAllPackages(token)),
    activateToast: (text) => dispatch(activateToast(text)),
    onShopNameChanged: (text) => dispatch(stickyAtcShopNameChanged(text)),
    getAllCoupons: (token) => dispatch(stickyAtcGetAllCoupons(token))
});

export default connect(mapStateToProps, mapDispatchToProps)(StickyAtcPackages);
