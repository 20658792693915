import React from 'react';
import '../css/App.css';
import {Button, TextStyle, Checkbox, Spinner, Card, TextField, Select, Heading} from '@shopify/polaris';
import '@shopify/polaris/dist/styles.css';
import ReactSearchBox from 'react-search-box';

const updateDiscountVersions = [
  {label: 'Price Rule Legacy', value: 'price_rule_legacy'},
  {label: 'Discount Code Basic', value: 'discount_code_basic'},
];

export const developers = [
  {label: 'Production (0)', value: '0'},
  {label: 'Ohad (1)', value: '1'},
  {label: 'Ariel (2)', value: '2'},
  {label: 'Leo (3)', value: '3'},
]

const App = ({shopName, onShopNameChanged, onSearch, searching, searchError, error, adminLink, gettingAllStores,
                 attributes, toggleAttributes, showAttributes, booleans, onBooleanChange, boolProcessing, stores,
                 modifiableBooleans, createProductWebhooks, deleteProductWebhooks, hardRefresh, resyncing,
                 installScripts, installingScripts, addScriptTag, onWidgetDevChange,
                 addingScriptTag, removeScriptTag, removingScriptTag, createStoreJs, creatingStoreJs,
                 generalSettings, onMoneyCssClassChange, isSubmitting, submitSettings, openSendReviewRequest,
                 showReviewRequestEmail, reviewRequestEmail, onReviewRequestEmailChange, sendReviewRequest,
                 sendingReviewRequest, modifiableNumbers, onUpdateDiscountVersionChange,
                 onNumberChange, numberProcessing, onSaveNumber, numberError, packages, onPackageChange,
                 packageChanging, bundlesAdminLink, main, onWidgetVersionChange, onGenSettingBoolChange}) =>
    <div>
        {gettingAllStores ? <Spinner /> : <div>
            <ReactSearchBox value={shopName} data={stores} onSelect={onShopNameChanged} onChange={onShopNameChanged}
                            placeholder="Shop domain" />
            <Button onClick={onSearch} loading={searching}>Search</Button>
            {searchError && <TextStyle variation={'negative'}>Something went wrong</TextStyle>}
            {error && <TextStyle variation="negative">Error: {error}</TextStyle>}
            {adminLink && <div>
                {adminLink && !attributes.disabled && <div><a href={`https://admin-m.zoorix.com/${attributes.shop_name}/${attributes.access_token}`} target="_blank" rel="noreferrer">Main Admin link ({shopName})</a></div>}
                {bundlesAdminLink && attributes.zoorix_bundles_installed && <div><a href={bundlesAdminLink} target="_blank" rel="noreferrer">Bundles Admin link ({shopName})</a></div>}
                <div><a href={`https://${shopName}`} target="_blank" rel="noreferrer">Visit shop</a></div>
                <div>App embeds link: {`https://${shopName}/admin/themes/current/editor?context=apps`}</div>
                <Button onClick={() => navigator.clipboard.writeText(`t = ShopifyToken.find_by(shop_name: '${shopName}')`)}>Copy code</Button>
                <Button onClick={toggleAttributes}>Toggle attributes</Button>
                {/*<Button onClick={createProductWebhooks}>Create product webhooks</Button>*/}
                {/*<Button onClick={deleteProductWebhooks}>Delete product webhooks</Button>*/}
                <Button onClick={hardRefresh} loading={resyncing}>Hard refresh shop data</Button>
                <Button onClick={installScripts} loading={installingScripts}>Install scripts</Button>
                <Button onClick={addScriptTag} loading={addingScriptTag}>Add script tag</Button>
                <Button onClick={removeScriptTag} loading={removingScriptTag}>Remove script tag</Button>
                <Button onClick={createStoreJs} loading={creatingStoreJs}>Create store js</Button>
                <Button onClick={() => navigator.clipboard.writeText(`https://app.zoorix.com/feedback_lp/${attributes.id}`)}>Copy review landing page link</Button>
                <Button onClick={openSendReviewRequest}>Send review request</Button>
                <Button onClick={() => navigator.clipboard.writeText(`https://api.zoorix.com/create_charge?id=${attributes.id}`)}>Copy charge link</Button>
                {showReviewRequestEmail && <div>
                    <TextField label="Email:" value={reviewRequestEmail}
                               onChange={onReviewRequestEmailChange} autoComplete={false} />
                    <Button onClick={sendReviewRequest} loading={sendingReviewRequest}>Send</Button>
                </div>}
                {showAttributes && Object.keys(attributes).map(key => <div key={key}>
                    {booleans.indexOf(key) >= 0 ? <Checkbox label={key}
                                                            disabled={boolProcessing || modifiableBooleans.indexOf(key) < 0}
                                                            checked={attributes[key]}
                                                            onChange={onBooleanChange(key)} /> :
                        modifiableNumbers.indexOf(key) >= 0 ?
                            <div>
                                <TextField label={key} value={attributes[key] && attributes[key].toString()}
                                           onChange={onNumberChange(key)} type={"number"}
                                           disabled={numberProcessing} />
                                <Button disabled={numberProcessing} onClick={onSaveNumber(key)}>Save {key}</Button>
                                {numberError[key] && <TextStyle variation={'negative'}>Something went wrong, {key} wasn't saved!</TextStyle>}
                            </div> :
                        key === 'widget_dev' ?
                            <Select label={'Widget Developer:'} options={developers}
                                    disabled={main.widgetDevProcessing}
                                    onChange={onWidgetDevChange} value={attributes.widget_dev ? attributes.widget_dev.toString() : '0'} /> :
                        key === 'package_id' ?
                            <div>
                                <Select label={'Package:'} options={packages} onChange={onPackageChange}
                                        value={attributes.package_id} disabled={packageChanging}
                                />
                            </div> :
                        key === 'update_discount_version' ?
                          <div>
                            <Select label={'Update Discount Version:'} options={updateDiscountVersions}
                                    onChange={onUpdateDiscountVersionChange} value={attributes.update_discount_version} />
                          </div>
                            :
                        key === 'widget_version_lock' ?
                            <div>
                                <Select label={'Widget Version Lock:'} options={main.availableWidgetMajorVersions}
                                        disabled={main.changingWidgetLockVersion}
                                        onChange={onWidgetVersionChange} value={attributes.widget_version_lock} />
                            </div> :
                        <div>{key}: {attributes[key] || 'null'}</div>}

                </div>)}
                <Heading>General settings</Heading>
                {showAttributes && Object.keys(generalSettings.values).map(key => <div key={key}>
                    {key === 'money_css_class' ? <Card title="Money">
                        <TextField label="Css class:" value={generalSettings.values.money_css_class}
                                   onChange={onMoneyCssClassChange}
                                   disabled={isSubmitting} autoComplete={false} />
                    </Card> : ['disable_zoorix_token_in_orders', 'ignore_vd_enable_disable',
                      'ignore_bundle_enable_disable', 'ignore_cross_sell_enable_disable'].indexOf(key) >= 0 ? <div>
                        <Checkbox label={key} disabled={boolProcessing} checked={generalSettings.values[key]}
                                  onChange={onGenSettingBoolChange(key)} />
                    </div> : <div>
                        {key}: {generalSettings.values[key]}
                    </div>}
                </div>)}

                <Button onClick={submitSettings} loading={isSubmitting}>Save settings</Button>
            </div>}
        </div>}


    </div>;

export default App;
